// 责任险接口
import { post } from './http';

// 华泰教培机构责任险提交订单
export function http_jpjg_createOrder(data) {
	return post('/insurance/api/enterpriseLiability/submitOrder', data);
}
// 根据保司id(companyId)获取职业代码
export function http_getInsProfessionCodeListByCompanyId(companyId) {
	return post(`/insurance/api/niumo/getInsProfessionCodeListByCompanyId/${companyId}`);
}