// 非旅游险投保接口
import { get, post } from './http';

// 获取商品列表
export function http_goods() {
	return get('/insurance/api/niumo/productList');
}

// 提交订单
export function http_submitBill(data) {
	return post('/insurance/api/niumo/submitOrder', data);
}

// 根据保险公司id获取职业类别
export function http_getJobList(companyId) {
	return get(`/insurance/api/niumo/getInsProductProfessionVoByInsurerId/${companyId}`);
}

// 根据保司id获取职业代码
export function http_getInsProfessionCodeListByCompanyId(companyId) {
	return post(`/insurance/api/niumo/getInsProfessionCodeListByCompanyId/${companyId}`);
}

// 根据产品id获取计划信息（包含计划的费率）
export function http_getPalns(proId) {
	return get(`/insurance/api/niumo/getPriceListByProId/${proId}`);
}

// 获取上次的投保人
export function http_getLastInsured() {
	return get('/insurance/api/niumo/getLastInsure');
}

// 新增常用被保险人
export function http_addAssured(data) {
	return post('/insurance/api/insCommonAssured/saveInsCommonAssured', data);
}

// 查询常用被保险人
export function http_getAssured(data) {
	return post('/insurance/api/insCommonAssured/getInsCommonAssuredPage', data);
}
